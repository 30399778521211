import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Panels: added missing records for “Has Recording?” and “Has recording on demand” on Contacts-Attempts view **`}</li>
      <li parentName="ul">{`Panels: added missing records for “Human Verified” on Contacts view **`}</li>
      <li parentName="ul">{`Sidetalk: Updated storybook version.`}</li>
      <li parentName="ul">{`Layout: Added Icon on v4 to redirect to studio`}</li>
      <li parentName="ul">{`Bug fix: fixed problem for skills where load = 0 on weighted and overflown distribution`}</li>
      <li parentName="ul">{`Bug Fix: Pages visits' tab on visitor’s bycard show pages related to the selected filter.`}</li>
      <li parentName="ul">{`Bug Fix: Fields Adding Order.`}</li>
      <li parentName="ul">{`Bug Fix: Adjusted SkillNameDecorator`}</li>
      <li parentName="ul">{`Bug Fix: Changing Date from period to fix date.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      